// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-dynamic-lights-js": () => import("./../src/pages/examples/dynamic-lights.js" /* webpackChunkName: "component---src-pages-examples-dynamic-lights-js" */),
  "component---src-pages-examples-engine-room-js": () => import("./../src/pages/examples/engine-room.js" /* webpackChunkName: "component---src-pages-examples-engine-room-js" */),
  "component---src-pages-examples-waves-and-rain-js": () => import("./../src/pages/examples/waves-and-rain.js" /* webpackChunkName: "component---src-pages-examples-waves-and-rain-js" */),
  "component---src-pages-experience-js": () => import("./../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-experience-pages-magnet-js": () => import("./../src/pages/experience-pages/magnet.js" /* webpackChunkName: "component---src-pages-experience-pages-magnet-js" */),
  "component---src-pages-experience-pages-solarsystem-js": () => import("./../src/pages/experience-pages/solarsystem.js" /* webpackChunkName: "component---src-pages-experience-pages-solarsystem-js" */),
  "component---src-pages-experiences-js": () => import("./../src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-hidden-loader-page-js": () => import("./../src/pages/hidden/LoaderPage.js" /* webpackChunkName: "component---src-pages-hidden-loader-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-scanner-js": () => import("./../src/pages/scanner.js" /* webpackChunkName: "component---src-pages-scanner-js" */)
}

